import React, { useState, useEffect } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { sortBy } from 'lodash';

import { ApiServiceServerless } from '../../../xhr_libs';
import AddEntityActionButtons from '../../forms/AddEntityActionButtons';
import { UserNotificationFrequencySelect } from '../../../pages/Profile/UserNotifications/UserNotificationFrequency';

export default function AddUserNotificationModal(props) {
  const {
    show,
    onHide,
    showToast,
    setNotifications,
    setActiveNotificationId,
    userOrganizations,
  } = props;

  const [notificationName, setNotificationName] = useState('');
  const [notificationType, setNotificationType] = useState('');
  const [organizationId, setOrganizationId] = useState('');
  const [frequencyOption, setFrequencyOption] = useState(0);
  const [frequencyValue, setFrequencyValue] = useState('0');

  const [disabled, setDisabled] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (notificationName && notificationType) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    if (notificationType === 'scheduled_reports' && !organizationId) {
      setDisabled(true);
    }
  }, [notificationName, notificationType, organizationId]);

  const addNotification = (e) => {
    e.preventDefault();
    setIsLoading(true);
    ApiServiceServerless.post(`/user_notifications`, {
      name: notificationName,
      notification_type: notificationType,
      organization_id: organizationId,
      frequency_option: frequencyOption,
      frequency_value: frequencyValue,
    })
      .then((res) => {
        setNotifications((prev) => [...prev, res.data]);
        setActiveNotificationId(res.data.id);
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        throw error;
      })
      .finally(() => {
        onHide();
        setIsLoading(false);
        resetModal();
      });
  };

  const resetModal = () => {
    setNotificationName('');
    setNotificationType('');
    setOrganizationId('');
    setFrequencyOption(0);
    setFrequencyValue('0');
  };

  useEffect(() => {
    if (show) {
      resetModal();
    }
  }, [show]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>Add Notification</Modal.Header>
      <Form onSubmit={addNotification}>
        <Modal.Body>
          <Form.Group>
            <Form.Label>
              {'Notification Name'}
              <span className='text-warning font-weight-bold pl-1'>*</span>
            </Form.Label>
            <Form.Row>
              <Form.Control
                required
                placeholder=''
                onChange={(e) => setNotificationName(e.target.value)}
              />
            </Form.Row>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              Notification Type
              <span className='text-warning font-weight-bold pl-1'>*</span>
            </Form.Label>

            <Form.Control
              as='select'
              required
              onChange={(e) => {
                setNotificationType(e.target.value);
                setOrganizationId('');
                setFrequencyOption(0);
                setFrequencyValue('0');
              }}
            >
              <option key={'opt-null'} value={''} hidden>
                -
              </option>
              <option key={'opt-automated_data'} value={'automated_data'}>
                Automated Data
              </option>

              <option key={'opt-scheduled_reports'} value={'scheduled_reports'}>
                Scheduled Reports
              </option>
            </Form.Control>
          </Form.Group>

          {notificationType === 'scheduled_reports' && (
            <Form.Group>
              <Form.Label>
                Organization
                <span className='text-warning font-weight-bold pl-1'>*</span>
              </Form.Label>

              <Form.Control
                as='select'
                required
                onChange={(e) => {
                  setOrganizationId(e.target.value);
                }}
              >
                <option key={'opt-null'} value={''} hidden>
                  -
                </option>
                {sortBy(userOrganizations, ['name']).map((org) => (
                  <option key={org.id} value={org.id}>
                    {org.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )}

          {notificationType && (
            <UserNotificationFrequencySelect
              notificationType={notificationType}
              frequencyOption={frequencyOption}
              setFrequencyOption={setFrequencyOption}
              frequencyValue={frequencyValue}
              setFrequencyValue={setFrequencyValue}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <AddEntityActionButtons
            onCancel={onHide}
            submitDisabled={disabled}
            isSubmitting={isLoading}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
