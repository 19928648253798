import React from 'react';
import { Row, Col, Table, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';

import ApiServiceServerless from '../../../xhr_libs';
import EnergyTracerTable from '../../../components/tables';
import PopoverTrigger from '../../../components/PopoverTrigger';
import CHARTCOLORS from '../../../helpers/chartColors';
import { ReportLookup } from '../../Reports/ReportHelpers';

const styles = {
  iconStyle: {
    fontSize: '21px',
    margin: '0 0.35em',
    verticalAlign: 'text-bottom',
    cursor: 'pointer',
    color: 'var(--et_tab_grey)',
    opacity: '1.0',
  },
  iconStyle2: {
    fontSize: '18px',
    color: CHARTCOLORS[1],
    marginLeft: '5px',
    cursor: 'pointer',
    position: 'absolute',
    left: '135px',
    top: '3px',
  },
  iconStyle3: {
    fontSize: '18px',
    color: CHARTCOLORS[1],
    marginLeft: '5px',
    cursor: 'pointer',
  },
};

export default function UserNotificationReportOptions(props) {
  const {
    activeNotification,
    setNotifications,
    showToast,
    isSaving,
    setIsSaving,
  } = props;

  const deleteNotificationOrganization = (report_id) => {
    setIsSaving(true);
    ApiServiceServerless.delete(`/user_notification_report/${report_id}/`)
      .then((res) => {
        setNotifications((prev) =>
          prev.map((notification) => {
            if (notification.id === activeNotification.id) {
              return { ...res.data };
            }
            return notification;
          })
        );
        showToast('success', 'Success', `Notification Report was deleted.`);
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        throw error;
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const tableColumns = [
    {
      dataField: 'report_primary_type',
      text: 'Report Primary Type',
      style: { paddingTop: '10px' },
      formatter: (cell, row) => (
        <Col>
          {
            ReportLookup.find(
              (report) => report.type === row.report_primary_type
            ).primaryName
          }
        </Col>
      ),
    },
    {
      dataField: 'report_secondary_type',
      text: 'Report Secondary Type',
      style: { paddingTop: '10px' },
      formatter: (cell, row) => (
        <Col>
          {
            ReportLookup.find(
              (report) => report.type === row.report_primary_type
            ).reportInfo.find(
              (report) => report.type === row.report_secondary_type
            ).name
          }
        </Col>
      ),
    },
    {
      dataField: 'payload',
      text: 'Options',
      style: { paddingTop: '10px' },
      formatter: (cell, row) => (
        <Col>
          <PopoverTrigger
            rootClose={true}
            popoverTitle={'Report Options'}
            popoverContent={
              <Table>
                <thead>
                  <tr>
                    <th>Key</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(row.payload).map((key, idx) => (
                    <tr key={idx}>
                      <td>{key}</td>
                      <td>{row.payload[key].toString()}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            }
            trigger={'click'}
            placement='right'
            popoverWidth={400}
          >
            <FontAwesomeIcon style={styles.iconStyle3} icon={faInfoCircle} />
          </PopoverTrigger>
        </Col>
      ),
    },
    {
      dataField: 'date_offsets',
      text: 'Date Offsets',
      style: { paddingTop: '10px' },
      formatter: (cell, row) => (
        <Col>
          <PopoverTrigger
            rootClose={true}
            popoverTitle={'Report Offset Options'}
            popoverContent={
              <Table>
                <thead>
                  <tr>
                    <th>Key</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(row.date_offsets).map((key, idx) => (
                    <tr key={idx}>
                      <td>{key}</td>
                      <td>{row.date_offsets[key].toString()}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            }
            trigger={'click'}
            placement='right'
            popoverWidth={400}
          >
            <FontAwesomeIcon style={styles.iconStyle3} icon={faInfoCircle} />
          </PopoverTrigger>
        </Col>
      ),
      headerFormatter: (column, colIndex) => (
        <>
          {column.text}
          <PopoverTrigger
            rootClose={true}
            popoverTitle={column.text + ' Desciption'}
            popoverContent={
              <div>
                Values that determine when the report should be run in relation
                to the current date. Values typically in months.
              </div>
            }
            trigger={'click'}
            placement='right'
          >
            <FontAwesomeIcon style={styles.iconStyle3} icon={faInfoCircle} />
          </PopoverTrigger>
        </>
      ),
    },
    {
      dataField: 'id',
      text: 'Actions',
      formatExtraData: {
        isSaving: isSaving,
      },
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <Col>
          <Button
            variant='danger'
            onClick={() => {
              deleteNotificationOrganization(cell);
            }}
            disabled={formatExtraData.isSaving}
          >
            <FontAwesomeIcon
              data-id='delete-org-btn'
              style={{
                fontSize: '20px',
                lineHeight: 'inherit',
                paddingTop: '2px',
              }}
              icon={faTrashAlt}
            />
          </Button>
        </Col>
      ),
    },
  ];
  return (
    <>
      <Row>
        <Col>
          <h5>{`Reports (${activeNotification.reports.length}/5)`}</h5>
          <PopoverTrigger
            rootClose={true}
            popoverTitle={'Report Notification Instructions'}
            popoverContent={
              <div>
                To add a report to this list visit the reports page then select
                your options and click the 'Save Options Button'.
              </div>
            }
            trigger={'click'}
            placement='right'
          >
            <FontAwesomeIcon style={styles.iconStyle2} icon={faInfoCircle} />
          </PopoverTrigger>
        </Col>
      </Row>
      <EnergyTracerTable
        data={activeNotification.reports}
        columns={tableColumns}
        keyField={'id'}
      />
    </>
  );
}
