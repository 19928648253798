import React, { useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { sortBy } from 'lodash';

import AddEntityActionButtons from '../../forms/AddEntityActionButtons';
import { ApiServiceServerless } from '../../../xhr_libs';

export default function AddUserNotificationOrganizationModal(props) {
  const {
    show,
    onHide,
    activeNotification,
    userOrganizations,
    notificationOrganizationIds,
    setNotifications,
    isSaving,
    setIsSaving,
    showToast,
  } = props;

  const [organizationId, setOrganizationId] = useState('');

  const clearForm = () => {
    setOrganizationId('');
  };

  const handleSubmit = (e) => {
    setIsSaving(true);
    e.preventDefault();

    ApiServiceServerless.post(
      `/user_notification_organization/${activeNotification.id}/${organizationId}`
    )
      .then((res) => {
        showToast('success', 'Success', 'Organization added to notification');
        setNotifications((prev) =>
          prev.map((notification) => {
            if (notification.id === activeNotification.id) {
              return { ...res.data };
            }
            return notification;
          })
        );
        onHide();
        clearForm();
        setIsSaving(false);
      })
      .catch((err) => {
        showToast('danger', 'Error', err);
        onHide();
        setIsSaving(false);
        throw err;
      });
  };

  const handleCancel = () => {
    onHide();
    clearForm();
  };

  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton style={{ height: '50px' }}>
          {'Add Organization to Notification'}
        </Modal.Header>
        <Form
          noValidate
          onSubmit={handleSubmit}
          style={{
            textAlign: 'left',
          }}
        >
          <Modal.Body>
            <Row>
              <Col sm={12}>
                <Form.Group>
                  <Form.Label>Organization </Form.Label>
                  <Form.Control
                    as='select'
                    onChange={(e) =>
                      setOrganizationId(parseInt(e.target.value))
                    }
                  >
                    <option key={'null-val'} value={''} hidden></option>
                    {userOrganizations.filter(
                      (org) => !notificationOrganizationIds.includes(org.id)
                    ).length === 0 && (
                      <option key={'null-null'} value={''} disabled>
                        No available organizations
                      </option>
                    )}
                    {sortBy(userOrganizations, ['name'])
                      .filter(
                        (org) => !notificationOrganizationIds.includes(org.id)
                      )
                      .map((org) => (
                        <option key={org.id} value={org.id}>
                          {org.name}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <AddEntityActionButtons
              onCancel={handleCancel}
              submitDisabled={organizationId === null}
              submitShow={true}
              isSubmitting={isSaving}
            />
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
