import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

import dayjs from 'dayjs';

import MONTHSARRAY from '../../../helpers/months';
import GHGReportUnitPicker from '../../../components/reports/GHGReportUnitPicker';

const styles = {
  form: {
    textAlignLast: 'center',
    display: 'block',
  },
  label: {
    display: 'flex',
  },
};

export default function GHGSummaryReport(props) {
  const { organizationData, updatePayload } = props;

  const [localBaselineYear, setLocalBaselineYear] = useState();
  const [localEmissionsYear, setLocalEmissionsYear] = useState();
  const [localEmissionsMonths, setLocalEmissionsMonths] = useState([]);
  const [localMonth, setLocalMonth] = useState(0);

  useEffect(() => {
    const temp_baseline_year = organizationData['trend_years'][0];
    setLocalBaselineYear(temp_baseline_year);
    updatePayload('baseline_year', temp_baseline_year);

    const temp_emissions_year =
      organizationData['trend_years'][
        organizationData['trend_years'].length - 1
      ];
    setLocalEmissionsYear(temp_emissions_year);
    updatePayload('end_year', temp_emissions_year);
  }, [organizationData, updatePayload]);

  useEffect(() => {
    let temp_months = [];
    let max_month = 12;
    if (parseInt(localEmissionsYear) === dayjs.utc(new Date()).year()) {
      max_month = dayjs.utc(new Date()).month() + 1;
    }
    for (let i = 0; i < max_month; i++) {
      temp_months.push(i);
    }
    setLocalEmissionsMonths(temp_months);
    setLocalMonth(max_month);
    updatePayload('end_month', max_month);
  }, [localEmissionsYear, updatePayload]);

  return (
    <>
      <Form style={styles.form}>
        <Form.Group controlId={'ghgBaslineYear'}>
          <Form.Label style={styles.label}>Baseline Year</Form.Label>
          <Form.Control
            as='select'
            onChange={(e) => {
              updatePayload('baseline_year', e.target.value);
              setLocalBaselineYear(e.target.value);
            }}
            value={localBaselineYear}
          >
            {organizationData['trend_years'].map((year) => (
              <option key={`report-opt-${year}`} value={year}>
                {year}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </Form>

      <Form style={styles.form}>
        <Form.Group controlId={'ghgBaslineYear'}>
          <Form.Label style={styles.label}>Emissions Year</Form.Label>
          <Form.Control
            as='select'
            onChange={(e) => {
              updatePayload('end_year', e.target.value);
              setLocalEmissionsYear(e.target.value);
            }}
            value={localEmissionsYear}
          >
            {organizationData['trend_years'].map((year) => (
              <option key={`report-opt-${year}`} value={year}>
                {year}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </Form>
      <Form style={styles.form}>
        <Form.Group controlId={'emissionsMonth'}>
          <Form.Label style={styles.label}>Emissions Month</Form.Label>
          <Form.Control
            as='select'
            onChange={(e) => {
              updatePayload('end_month', parseInt(e.target.value));
              setLocalMonth(parseInt(e.target.value));
            }}
            value={localMonth}
          >
            {localEmissionsMonths.map((month, index) => (
              <option key={`report-opt-${index + 1}`} value={index + 1}>
                {MONTHSARRAY[month]}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <GHGReportUnitPicker updatePayload={updatePayload} />
      </Form>
    </>
  );
}
