import { React, useEffect, useState } from 'react';
import { Form, Col } from 'react-bootstrap';

import ResourceDetails from '../../../components/ResourceDetails';

export const FREQUENCY_OPTION_LOOKUP = {
  0: 'Off',
  1: 'Daily',
  2: 'Weekly',
  3: 'Monthly (By Date)',
  4: 'Monthly (By Week)',
};

export const FREQUENCY_VALUE_LOOKUP = {
  0: null,
  1: [
    ['0', '1'],
    ['Including Weekends', 'Weekdays Only'],
  ],
  2: [
    ['1', '2', '3', '4', '5', '6', '7'],
    [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ],
  ],
  3: [
    [
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
      '24',
      '25',
      '26',
      '27',
      '28',
      '29',
      '30',
      '31',
    ],
    [
      '1st',
      '2nd',
      '3rd',
      '4th',
      '5th',
      '6th',
      '7th',
      '8th',
      '9th',
      '10th',
      '11th',
      '12th',
      '13th',
      '14th',
      '15th',
      '16th',
      '17th',
      '18th',
      '19th',
      '20th',
      '21st',
      '22nd',
      '23rd',
      '24th',
      '25th',
      '26th',
      '27th',
      '28th',
      '29th',
      '30th',
      '31st',
    ],
  ],
  4: [
    ['1', '2', '3', '4'],
    ['1st', '2nd', '3rd', '4th'],
    ['1', '2', '3', '4', '5', '6', '7'],
    [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ],
  ],
};

const AVAILABLE_FREQUENCY_OPTIONS = {
  automated_data: [0, 1, 2, 3, 4],
  scheduled_reports: [0, 3, 4],
};

export function UserNotificationFrequencySelect(props) {
  const {
    notificationType,
    frequencyOption,
    setFrequencyOption,
    frequencyValue,
    setFrequencyValue,
  } = props;

  const [option4Value1, setOption4Value1] = useState('1');
  const [option4Value2, setOption4Value2] = useState('1');

  useEffect(() => {
    if (frequencyOption === 4) {
      setFrequencyValue(`${option4Value1},${option4Value2}`);
    }
  }, [frequencyOption, option4Value1, option4Value2, setFrequencyValue]);

  const handleChangeFrequencyOption = (value) => {
    setFrequencyOption(value);
    if (value === 0) {
      setFrequencyValue('0');
    }
    if ([1, 2, 3].includes(value)) {
      setFrequencyValue(FREQUENCY_VALUE_LOOKUP[value][0][0]);
    }

    if (value === 4) {
      setOption4Value1('1');
      setOption4Value2('1');
    }
  };

  return (
    <>
      <Form.Group>
        <Form.Label>
          Frequency Option
          <span className='text-warning font-weight-bold pl-1'>*</span>
        </Form.Label>

        <Form.Control
          as='select'
          required
          value={frequencyOption}
          onChange={(e) =>
            handleChangeFrequencyOption(parseInt(e.target.value))
          }
        >
          {Object.keys(FREQUENCY_OPTION_LOOKUP).map(
            (opt) =>
              AVAILABLE_FREQUENCY_OPTIONS[notificationType].includes(
                parseInt(opt)
              ) && (
                <option key={`opt-${opt}`} value={opt}>
                  {FREQUENCY_OPTION_LOOKUP[opt]}
                </option>
              )
          )}
        </Form.Control>
      </Form.Group>
      {[1, 2, 3].includes(frequencyOption) && (
        <Form.Group>
          <Form.Label>
            Frequency Option Value
            <span className='text-warning font-weight-bold pl-1'>*</span>
          </Form.Label>

          <Form.Control
            as='select'
            required
            value={frequencyValue}
            onChange={(e) => setFrequencyValue(e.target.value)}
          >
            {FREQUENCY_VALUE_LOOKUP[frequencyOption][0].map((val, index) => (
              <option key={`opt-${val}`} value={val}>
                {FREQUENCY_VALUE_LOOKUP[frequencyOption][1][index]}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      )}
      {frequencyOption === 4 && (
        <>
          <Form.Group>
            <Form.Label>
              Frequency Option Day Value
              <span className='text-warning font-weight-bold pl-1'>*</span>
            </Form.Label>

            <Form.Control
              as='select'
              required
              value={option4Value1}
              onChange={(e) => setOption4Value1(e.target.value)}
            >
              {FREQUENCY_VALUE_LOOKUP[4][0].map((val, index) => (
                <option key={`opt-${val}`} value={val}>
                  {FREQUENCY_VALUE_LOOKUP[4][1][index]}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              Frequency Option Week Value
              <span className='text-warning font-weight-bold pl-1'>*</span>
            </Form.Label>

            <Form.Control
              as='select'
              required
              value={option4Value2}
              onChange={(e) => setOption4Value2(e.target.value)}
            >
              {FREQUENCY_VALUE_LOOKUP[4][2].map((val, index) => (
                <option key={`opt-${val}`} value={val}>
                  {FREQUENCY_VALUE_LOOKUP[4][3][index]}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </>
      )}
    </>
  );
}

export function UserNotificationFrequencyDisplay(props) {
  const { frequencyOption, frequencyValue } = props;

  return (
    <>
      <Col md={2}>
        <ResourceDetails
          header='Frequency Option'
          value={FREQUENCY_OPTION_LOOKUP[frequencyOption]}
        />
      </Col>

      {[1, 2, 3].includes(frequencyOption) && (
        <Col md={2}>
          <ResourceDetails
            header='Frequency Value'
            value={
              FREQUENCY_VALUE_LOOKUP[frequencyOption][1][
                FREQUENCY_VALUE_LOOKUP[frequencyOption][0].indexOf(
                  frequencyValue
                )
              ]
            }
          />
        </Col>
      )}
      {frequencyOption === 4 && (
        <>
          <Col md={2}>
            <ResourceDetails
              header='Frequency Week Value'
              value={
                FREQUENCY_VALUE_LOOKUP[frequencyOption][1][
                  FREQUENCY_VALUE_LOOKUP[frequencyOption][0].indexOf(
                    frequencyValue.split(',')[0]
                  )
                ]
              }
            />
          </Col>
          <Col md={2}>
            <ResourceDetails
              header='Frequency Day Value'
              value={
                FREQUENCY_VALUE_LOOKUP[frequencyOption][3][
                  FREQUENCY_VALUE_LOOKUP[frequencyOption][2].indexOf(
                    frequencyValue.split(',')[1]
                  )
                ]
              }
            />
          </Col>
        </>
      )}
    </>
  );
}
