import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { sortBy } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBellPlus } from '@fortawesome/pro-solid-svg-icons';

import ApiServiceServerless from '../../../xhr_libs';
import Loader from '../../../components/Loader';
import UserNotification from './UserNotification';
import AddUserNotificationModal from '../../../components/modals/UserNotification/AddUserNotificationModal';
import SmallerLoader from '../../../components/SmallerLoader';

const getStyles = (disabled) => {
  return {
    iconStyle: {
      fontSize: '23px',
      margin: '0.15em 0.5em 0em 0.5em',
      verticalAlign: 'text-bottom',
      cursor: disabled ? 'none' : 'pointer',
      color: 'var(--et_tab_grey)',
      opacity: disabled ? '0.5' : '1.0',
    },
  };
};

export default function UserNotifications(props) {
  const { showToast, userOrganizations } = props;

  const [notifications, setNotifications] = useState([]);
  const [activeNotificationId, setActiveNotificationId] = useState(null);
  const [activeNotification, setActiveNotification] = useState(null);

  const [showAddModal, setShowAddModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (activeNotificationId !== null) {
      let notification = notifications.find(
        (notification) => notification.id === activeNotificationId
      );
      setActiveNotification(notification ? notification : null);
    } else {
      setActiveNotification(null);
    }
  }, [notifications, activeNotificationId]);

  const getNotifications = useCallback(() => {
    setIsLoading(true);
    ApiServiceServerless.get('/user_notifications')
      .then((res) => {
        setNotifications(res.data);
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        throw error;
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [showToast]);

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  return (
    <>
      {isLoading && <Loader />}{' '}
      {!isLoading && (
        <>
          <Row>
            <Col style={{ display: 'inline-flex' }}>
              <Row>
                <Form inline style={{ marginBottom: '0.5em' }}>
                  <Form.Label
                    style={{
                      display: 'block',
                      marginRight: '0.5em',
                      marginLeft: '0.5em',
                    }}
                  >
                    Notification:
                  </Form.Label>
                  <Form.Control
                    style={{ float: 'right' }}
                    as='select'
                    size='sm'
                    value={activeNotificationId ? activeNotificationId : ''}
                    onChange={(e) => {
                      let pid = e.target.value;
                      setActiveNotificationId(parseInt(pid));
                      setActiveNotification(null);
                    }}
                  >
                    <option hidden value={'null'} key={'null-opt'}>
                      Select a notification
                    </option>
                    {notifications.length === 0 && (
                      <option disabled value={'dis'} key={'null-dis'}>
                        No Notifications
                      </option>
                    )}
                    {sortBy(notifications, ['name']).map((notification) => (
                      <option
                        key={`notification-opt-${notification.id}-${notification.notification_type}`}
                        value={notification.id}
                      >
                        {notification.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form>
                <FontAwesomeIcon
                  style={getStyles(isSaving).iconStyle}
                  icon={faBellPlus}
                  title={'Add Notification'}
                  disabled={isSaving}
                  onClick={() => {
                    if (!isSaving) {
                      setShowAddModal(true);
                    }
                  }}
                />
                {isSaving && (
                  <div style={{ marginTop: '4px' }}>
                    <SmallerLoader style={{ fontSize: '18px' }} />
                  </div>
                )}
              </Row>
            </Col>
          </Row>
          {activeNotificationId !== null && activeNotification !== null && (
            <UserNotification
              activeNotification={activeNotification}
              userOrganizations={userOrganizations}
              setNotifications={setNotifications}
              setActiveNotificationId={setActiveNotificationId}
              showToast={showToast}
              setIsLoading={setIsLoading}
              isSaving={isSaving}
              setIsSaving={setIsSaving}
            />
          )}
        </>
      )}
      <AddUserNotificationModal
        show={showAddModal}
        onHide={() => {
          setShowAddModal(false);
        }}
        showToast={showToast}
        setNotifications={setNotifications}
        setActiveNotificationId={setActiveNotificationId}
        userOrganizations={userOrganizations}
      />
    </>
  );
}
