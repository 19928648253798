import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'react-bootstrap';

import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarEdit,
  faTimesCircle,
} from '@fortawesome/pro-solid-svg-icons';

import PopoverTrigger from '../../components/PopoverTrigger';
import DashboardBaselineDateSelect from './DashboardBaselineDateSelect';
import CHARTCOLORS from '../../helpers/chartColors';

import { ApiServiceServerless } from '../../xhr_libs';
import SmallerLoader from '../../components/SmallerLoader';

const getStyles = (isLoading) => ({
  inputStyle: {
    width: '220px',
    textAlign: 'left',
    marginRight: '10px',
    paddingLeft: '20px',
  },
  buttonStyle: {
    fontSize: '24px',
    color: !isLoading ? CHARTCOLORS[1] : CHARTCOLORS[2],
  },
  button2Style: {
    padding: '0px',
    paddingTop: '3px',
    marginTop: '1px',
    borderWidth: '0px',
    borderRadius: '50%',
    height: '26px',
    width: '26px',
    position: 'absolute',
    right: '4%',
    top: '8%',
  },
  buttonContainerStyle: {
    display: 'inline-block',
    cursor: 'pointer',
    pointerEvents: !isLoading ? 'auto' : 'none',
    position: 'absolute',
    right: '12%',
    top: '9%',
  },
});

function DashboardBaselineDateSelectContainer(props) {
  const { orgId, endDate, dateOption, setBaselineData, customStyle } = props;

  const [isLoading, setIsLoading] = useState(false);

  const [baselineStartDate, setBaselineStartDate] = useState();
  const [baselineEndDate, setBaselineEndDate] = useState();

  useEffect(() => {
    if (orgId && baselineEndDate) {
      setIsLoading(true);
      ApiServiceServerless.get(
        `/dashboard/${orgId}/${dayjs
          .utc(baselineEndDate)
          .format('YYYY-MM-DD')}/${dateOption}`,
        { authorization_id: orgId }
      )
        .then(({ data }) => {
          setBaselineData(data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          throw err;
        });
    }
  }, [orgId, baselineEndDate, dateOption, setBaselineData]);

  const resetBaselineData = useCallback(() => {
    setBaselineStartDate();
    setBaselineEndDate();
    setBaselineData({});
  }, [setBaselineData]);

  useEffect(() => {
    resetBaselineData();
  }, [endDate, orgId, resetBaselineData]);

  const styles = getStyles(isLoading);
  return (
    <>
      <div
        style={{
          display: 'flex',
          position: 'relative',
          ...customStyle,
        }}
      >
        <>
          {' '}
          <Form.Label
            style={{
              marginRight: '0.5em',
              marginBottom: '0',
              paddingTop: '0.15em',
            }}
          >
            Baseline Year:
          </Form.Label>
          <Form.Control
            style={styles.inputStyle}
            size='sm'
            disabled
            value={
              baselineStartDate && baselineEndDate
                ? dayjs.utc(baselineStartDate).format('MMM YYYY') +
                  ' - ' +
                  dayjs.utc(baselineEndDate).format('MMM YYYY')
                : 'Select Baseline'
            }
          />
          <div
            id='dashboard-baseline-popover-button'
            style={styles.buttonContainerStyle}
          >
            <PopoverTrigger
              rootClose={true}
              popoverTitle={'End Date'}
              popoverContent={
                <DashboardBaselineDateSelect
                  endDate={endDate}
                  baselineEndDate={baselineEndDate}
                  setBaselineStartDate={setBaselineStartDate}
                  setBaselineEndDate={setBaselineEndDate}
                />
              }
              trigger='click'
              placement='bottom'
            >
              <FontAwesomeIcon
                style={styles.buttonStyle}
                icon={faCalendarEdit}
                id={'baseline-baseline-date-popover'}
              />
            </PopoverTrigger>
          </div>
          <Button variant='outline-danger' style={styles.button2Style}>
            <FontAwesomeIcon
              style={{ fontSize: '20px', lineHeight: 'inherit' }}
              icon={faTimesCircle}
              onClick={() => resetBaselineData()}
            />
          </Button>
        </>
        <div style={{ position: 'absolute', right: '-15px', top: '4px' }}>
          {' '}
          {isLoading && <SmallerLoader />}
        </div>
      </div>
    </>
  );
}

DashboardBaselineDateSelectContainer.propTypes = {
  customStyle: PropTypes.object,
};

DashboardBaselineDateSelectContainer.defaultProps = {
  customStyle: {},
};

export default DashboardBaselineDateSelectContainer;
