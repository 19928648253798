import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import dayjs from 'dayjs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarEdit } from '@fortawesome/pro-solid-svg-icons';

import PopoverTrigger from '../../../components/PopoverTrigger';
import ReportDatePicker from '../../../components/reports/ReportDatePicker';
import CHARTCOLORS from '../../../helpers/chartColors';

const styles = {
  label: {
    display: 'flex',
  },
  inputStyle: {
    textAlign: 'center',
    marginRight: '10px',
  },
  buttonStyle: {
    fontSize: '22px',
    color: CHARTCOLORS[1],
  },
  buttonContainerStyle: {
    cursor: 'pointer',
    pointerEvents: 'auto',
    position: 'relative',
    float: 'right',
    marginTop: '-26px',
    marginRight: '5px',
  },
};

export default function ProjectMonthRange(props) {
  const {
    major_label,
    label,
    default_date,
    min_date,
    max_date,
    payload_key,
    updatePayload,
    showPicker,
  } = props;

  const [currentDate, setCurrentDate] = useState('');
  const [dateString, setDateString] = useState('');

  const setDate = useCallback(
    (date) => {
      let new_date = null;
      if (date === 'Invalid Date') {
        new_date = dayjs.utc(new Date()).endOf('month').format('YYYY-MM-DD');
      } else {
        new_date = dayjs.utc(date).endOf('month').format('YYYY-MM-DD');
      }
      setCurrentDate(new_date);
      updatePayload(payload_key, new_date);
    },
    [payload_key, updatePayload]
  );

  useEffect(() => {
    if (default_date) {
      setDate(default_date);
    }
  }, [setDate, default_date]);

  useEffect(() => {
    if (currentDate) {
      let str = '';
      const local_date = dayjs(currentDate);
      str =
        local_date.month(local_date.month() - 11).format('MMMM YYYY') +
        ' - ' +
        local_date.format('MMMM YYYY');

      setDateString(str);
    }
  }, [currentDate, payload_key]);

  return (
    <Form.Group>
      <Form.Label style={styles.label}>{major_label}</Form.Label>
      <Form.Control
        style={styles.inputStyle}
        size='sm'
        disabled
        value={dateString}
      />
      <div
        id='report-popover-button'
        style={styles.buttonContainerStyle}
        hidden={!showPicker}
      >
        <PopoverTrigger
          rootClose={true}
          popoverTitle={label}
          popoverContent={
            <ReportDatePicker
              currentDate={currentDate}
              setDate={setDate}
              minDate={min_date}
              maxDate={max_date}
            />
          }
          trigger='click'
          placement='bottom'
        >
          <FontAwesomeIcon
            style={styles.buttonStyle}
            icon={faCalendarEdit}
            id={'report-date-popover'}
          />
        </PopoverTrigger>
      </div>
    </Form.Group>
  );
}
