import React, { useState, useEffect, useCallback } from 'react';
import { Tabs, Tab, Container } from 'react-bootstrap';
import Helmet from 'react-helmet';

import { ApiServiceServerless } from '../../xhr_libs';
import { useTabUrl } from '../../helpers/tracked';

import ReportTab from './ReportTab';

const PAGE_TITLE = 'Reports';

export default function Reports(props) {
  const {
    showToast,
    userSelectedOrganization,
    userSelectedOrganizationDetails,
    setDisableOrganizationSelect,
  } = props;
  const [activeTab, setActiveTab] = useTabUrl('basic-reporting');
  const [tabList, setTabList] = useState(['basic-reporting']);

  const [organizationData, setOrganizationData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);
  const [payload, setPayload] = useState({});

  const [userNotifications, setUserNotifications] = useState([]);

  const [activeAnalysisLicense, setActiveAnalysisLicense] = useState(false);
  const [activeGHGLicense, setActiveGHGLicense] = useState(false);

  const addToTabList = useCallback(
    (key) => {
      if (!tabList.includes(key)) {
        let temp_keys = [...tabList];
        temp_keys.push(key);
        setTabList(temp_keys);
      }
    },
    [tabList]
  );

  useEffect(() => {
    setDisableOrganizationSelect(isLoading || isGeneratingReport);
  }, [isLoading, isGeneratingReport, setDisableOrganizationSelect]);

  useEffect(() => {
    addToTabList(activeTab);
  }, [activeTab, addToTabList]);

  useEffect(() => {
    let tempAnalysisLicense = false;
    let tempGHGLicense = false;
    if (userSelectedOrganizationDetails.id) {
      if (
        userSelectedOrganizationDetails &&
        userSelectedOrganizationDetails.licenses &&
        userSelectedOrganizationDetails.licenses.analysis
      ) {
        tempAnalysisLicense = true;
      }
      if (
        userSelectedOrganizationDetails &&
        userSelectedOrganizationDetails.licenses &&
        userSelectedOrganizationDetails.licenses.ghg
      ) {
        tempGHGLicense = true;
      }
    }

    setActiveAnalysisLicense(tempAnalysisLicense);
    setActiveGHGLicense(tempGHGLicense);
  }, [userSelectedOrganizationDetails]);

  const updatePayload = useCallback((key, val) => {
    setPayload((prev) => ({ ...prev, [key]: val }));
  }, []);

  const getOrganizationData = useCallback(
    (org_id) => {
      setIsLoading(true);
      ApiServiceServerless.get(`/reports/${org_id}`, {
        authorization_id: org_id,
      })
        .then(({ data }) => {
          setOrganizationData(data);
          setIsLoading(false);
        })
        .catch((error) => {
          setOrganizationData({});
          setIsLoading(false);
          showToast('danger', 'Error', error);
          throw error;
        });
    },
    [showToast]
  );

  useEffect(() => {
    if (userSelectedOrganization.id) {
      getOrganizationData(userSelectedOrganization.id);
    } else {
      setIsLoading(false);
    }
  }, [userSelectedOrganization.id, getOrganizationData]);

  const getNotifications = useCallback(() => {
    setIsLoading(true);
    ApiServiceServerless.get('/user_notifications')
      .then((res) => {
        setUserNotifications(res.data);
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        throw error;
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [showToast]);

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  return (
    <>
      <Container className='et-container et-main-tab-content' tabtype='flex'>
        <Helmet>
          <title>{PAGE_TITLE}</title>
        </Helmet>

        <Tabs
          id='report-tabs'
          activeKey={activeTab}
          onSelect={setActiveTab}
          className='et-main-tabs'
        >
          <Tab eventKey='basic-reporting' title={'Basic Reporting'}>
            {tabList.includes('basic-reporting') && (
              <ReportTab
                variant={'basic'}
                showToast={showToast}
                userSelectedOrganization={userSelectedOrganization}
                organizationData={organizationData}
                payload={payload}
                updatePayload={updatePayload}
                isLoading={isLoading}
                isGeneratingReport={isGeneratingReport}
                setIsGeneratingReport={setIsGeneratingReport}
                activeAnalysisLicense={activeAnalysisLicense}
                activeGHGLicense={activeGHGLicense}
                userNotifications={userNotifications}
                setUserNotifications={setUserNotifications}
              />
            )}
          </Tab>
          <Tab eventKey='advanced-reporting' title={'Advanced Reporting'}>
            {tabList.includes('advanced-reporting') && (
              <ReportTab
                variant={'advanced'}
                showToast={showToast}
                userSelectedOrganization={userSelectedOrganization}
                organizationData={organizationData}
                payload={payload}
                updatePayload={updatePayload}
                isLoading={isLoading}
                isGeneratingReport={isGeneratingReport}
                setIsGeneratingReport={setIsGeneratingReport}
                activeAnalysisLicense={activeAnalysisLicense}
                activeGHGLicense={activeGHGLicense}
                userNotifications={userNotifications}
                setUserNotifications={setUserNotifications}
              />
            )}
          </Tab>
          <Tab eventKey='ghg-reporting' title={'GHG Reporting'}>
            {tabList.includes('ghg-reporting') && (
              <ReportTab
                variant={'ghg'}
                showToast={showToast}
                userSelectedOrganization={userSelectedOrganization}
                organizationData={organizationData}
                payload={payload}
                updatePayload={updatePayload}
                isLoading={isLoading}
                isGeneratingReport={isGeneratingReport}
                setIsGeneratingReport={setIsGeneratingReport}
                activeAnalysisLicense={activeAnalysisLicense}
                activeGHGLicense={activeGHGLicense}
                userNotifications={userNotifications}
                setUserNotifications={setUserNotifications}
              />
            )}
          </Tab>
        </Tabs>
      </Container>
    </>
  );
}
