import React, { useEffect, useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';

import { ApiServiceServerless } from '../../xhr_libs';
import {
  ReportLookup,
  generateSimpleFilename,
  NoDataReports,
  NoReportsTypes,
  TrendReports,
} from './ReportHelpers';
import ConfirmationModal, {
  useConfirm,
} from '../../components/modals/ConfirmationModal';
import SmallerLoader from '../../components/SmallerLoader';
import AddUserNotificationReportModal from '../../components/modals/UserNotification/AddUserNotificationReportModal';

const styles = {
  submitButton: {
    width: '100%',
  },
};

export function ReportSubmit(props) {
  const {
    organization_id,
    organization_name,
    organizationData,
    payload,
    reportPrimaryType,
    reportSecondaryType,
    reportDisabled,
    isLoading,
    performanceDisabled,
    performanceWarning,
    trendWarning,
    feasibilityWarning,
    showToast,
    isGeneratingReport,
    setIsGeneratingReport,
    userNotifications,
    setUserNotifications,
  } = props;

  const [confirmationModalProps, withConfirm] = useConfirm();

  const [localPerformanceDisabled, setLocalPerformanceDisabled] =
    useState(false);

  const [openSaveReportModal, setOpenSaveReportModal] = useState(false);

  useEffect(() => {
    if (
      (reportPrimaryType === 'performance' ||
        reportPrimaryType === 'analysis') &&
      performanceDisabled
    ) {
      setLocalPerformanceDisabled(true);
    } else {
      setLocalPerformanceDisabled(false);
    }
  }, [reportPrimaryType, performanceDisabled]);

  const handleSubmitReport = () => {
    if (validateReport()) {
      if (reportPrimaryType === 'performance') {
        if (performanceWarning) {
          withConfirm(
            'Warning: You are about to create a report that is missing complete data from at least one month. If you continue, the reported values may change as missing data is entered. Are you sure you want to continue?',
            submitReport
          );
        } else {
          submitReport();
        }
      } else if (
        reportPrimaryType === 'trend' &&
        reportSecondaryType === 'trend_building'
      ) {
        if (trendWarning) {
          withConfirm(
            'Warning: You are about to create a report is quite large. For report clarity and generation time it is recommended to select fewer buildings and/or report options. Are you sure you want to generate this report?',
            submitReport
          );
        } else {
          submitReport();
        }
      } else if (reportPrimaryType === 'feasibility') {
        if (feasibilityWarning) {
          withConfirm(
            'Warning: You are about to create a report is quite large. For report clarity and generation time it is recommended to select fewer buildings or unselect the building charts option. Are you sure you want to generate this report?',
            submitReport
          );
        } else {
          submitReport();
        }
      } else {
        submitReport();
      }
    }
  };

  const callMakeFindReportRequest = (
    report,
    fileName,
    report_id,
    extension,
    attempt
  ) => {
    setTimeout(() => {
      makeFindReportRequest(report, fileName, report_id, extension, attempt);
    }, 3000);
  };

  const makeFindReportRequest = (
    report,
    fileName,
    report_id,
    extension,
    attempt
  ) => {
    if (attempt < 10) {
      ApiServiceServerless.get(`/reports/retrieve/${report_id}/${extension}`, {
        ...report['opts'],
        authorization_id: organization_id,
      })
        .then((res) => {
          if (res.data && res.data.byteLength && res.data.byteLength > 4) {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            handleSetReport(url, fileName);
            setIsGeneratingReport(false);
          } else {
            callMakeFindReportRequest(
              report,
              fileName,
              report_id,
              extension,
              attempt + 1
            );
          }
        })
        .catch((error) => {
          setIsGeneratingReport(false);
          handleReportError(error, showToast);
          throw error;
        });
    } else {
      showToast(
        'warning',
        'Warning',
        'This report is taking awhile to generate. A copy will be sent to you via email when it is completed'
      );
      setIsGeneratingReport(false);
    }
  };

  const generateDelayedReport = (report, fileName) => {
    ApiServiceServerless.post(
      `/reports/${report.type}`,
      { ...payload, organization_id: organization_id },
      {
        authorization_id: organization_id,
      }
    )
      .then((res) => {
        callMakeFindReportRequest(
          report,
          fileName,
          res.data['report_id'],
          res.data['extension'],
          0
        );
      })
      .catch((error) => {
        setIsGeneratingReport(false);
        handleReportError(error, showToast);
        throw error;
      });
  };

  const generateReport = (report, fileName) => {
    ApiServiceServerless.post(
      `/reports/${report.type}`,
      { ...payload, organization_id: organization_id },
      {
        ...report['opts'],
        authorization_id: organization_id,
      }
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        handleSetReport(url, fileName);
      })

      .catch((error) => {
        handleReportError(error, showToast);
        throw error;
      })
      .finally(() => {
        setIsGeneratingReport(false);
      });
  };

  const submitReport = () => {
    setIsGeneratingReport(true);

    const report = ReportLookup.find(
      (report) => report.type === reportPrimaryType
    ).reportInfo.find((report) => report.type === reportSecondaryType);
    const fileName = generateSimpleFilename(organization_name, report);

    if (report.report_service === 2) {
      generateReport(report, fileName);
    } else if (report.report_service === 3) {
      generateDelayedReport(report, fileName);
    }
  };

  const handleReportError = (error, showToast) => {
    let variant = 'danger';
    let title = 'Error';
    if (
      error.response &&
      error.response.status &&
      error.response.status === 501
    ) {
      variant = 'warning';
      title = 'Warning';
    }

    showToast(variant, title, error);
  };

  const handleSetReport = (url, fileName) => {
    const old_link = document.getElementById('report-download-link');
    if (old_link) {
      old_link.remove();
    }
    const link = document.createElement('a');
    link.href = url;
    link.id = 'report-download-link';
    link.target = '_blank';
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  };

  const validateReport = () => {
    if (
      !NoDataReports.includes(reportSecondaryType) &&
      'utility_types' in payload &&
      payload['utility_types'].length === 0
    ) {
      showToast(
        'danger',
        'Error',
        'Report must include at least 1 utility type.'
      );
      return false;
    }
    if (
      !NoReportsTypes.includes(reportSecondaryType) &&
      'report_types' in payload &&
      payload['report_types'].length === 0
    ) {
      showToast(
        'danger',
        'Error',
        'Report must include at least 1 report type.'
      );
      return false;
    }
    if (
      'baseline_year' in payload &&
      !payload['baseline_year'] &&
      TrendReports.includes(reportSecondaryType)
    ) {
      showToast('danger', 'Error', 'Trend Report must have a baseline year.');
      return false;
    }
    if (
      reportSecondaryType === 'usage' &&
      (new Date(payload['end_year'], payload['end_month'], 1) -
        new Date(payload['start_year'], payload['start_month'], 1)) /
        1000 /
        3600 /
        24 /
        365 >=
        5
    ) {
      showToast('danger', 'Error', 'Usage Report may only contain 5 years.');
      return false;
    }

    if (
      reportSecondaryType === 'ghg_summary' &&
      parseInt(payload['emissions_year']) - parseInt(payload['baseline_year']) <
        1
    ) {
      showToast(
        'danger',
        'Error',
        'Baseline Year Must Be Before Emissions Year.'
      );
      return false;
    }
    return true;
  };

  return (
    <>
      {' '}
      <ConfirmationModal
        {...confirmationModalProps}
        submitVariant={'success'}
        confirmText={'Generate Report'}
      />
      <Row>
        <Col md={8} style={{ padding: '0 5px 0 15px' }}>
          <Button
            style={styles.submitButton}
            variant='primary'
            type='submit'
            disabled={
              reportDisabled ||
              localPerformanceDisabled ||
              isGeneratingReport ||
              isLoading
            }
            onClick={() => handleSubmitReport()}
          >
            {!isGeneratingReport && 'Generate Report'}
            {isGeneratingReport && <SmallerLoader text={'Generating Report'} />}
          </Button>
        </Col>
        <Col md={4} style={{ padding: '0 15px 0 5px' }}>
          <Button
            style={styles.submitButton}
            variant='success'
            type='submit'
            disabled={
              reportDisabled ||
              localPerformanceDisabled ||
              isGeneratingReport ||
              isLoading
            }
            onClick={() => setOpenSaveReportModal(true)}
          >
            Save Report Settings
          </Button>
        </Col>
      </Row>
      <AddUserNotificationReportModal
        show={openSaveReportModal}
        onHide={() => {
          setOpenSaveReportModal(false);
        }}
        ReportLookup={ReportLookup}
        organizationData={organizationData}
        organization_id={organization_id}
        organization_name={organization_name}
        userNotifications={userNotifications}
        setUserNotifications={setUserNotifications}
        reportPrimaryType={reportPrimaryType}
        reportSecondaryType={reportSecondaryType}
        payload={payload}
        showToast={showToast}
      />
    </>
  );
}
