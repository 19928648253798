import React, { useEffect, useState } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { sortBy } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuildings } from '@fortawesome/pro-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';

import ApiServiceServerless from '../../../xhr_libs';
import EnergyTracerTable from '../../../components/tables';
import AddUserNotificationOrganizationModal from '../../../components/modals/UserNotification/AddUserNotificationOrganizationModal';

const getStyles = (disabled) => {
  return {
    iconStyle: {
      fontSize: '21px',
      margin: '0 0.35em',
      verticalAlign: 'text-bottom',
      cursor: disabled ? 'none' : 'pointer',
      color: 'var(--et_tab_grey)',
      opacity: disabled ? '0.5' : '1.0',
    },
  };
};

export default function UserNotificationOrganizations(props) {
  const {
    userOrganizations,
    activeNotification,
    setNotifications,
    showToast,
    isSaving,
    setIsSaving,
  } = props;

  const [notificationOrganizations, setNotificationOrganizations] = useState(
    []
  );
  const [sortedNotificationOrganizations, setSortedNotificationOrganizations] =
    useState([]);
  const [notificationOrganizationIds, setNotificationOrganizationIds] =
    useState([]);

  const [showAddOrganizationModal, setShowAddOrganizationModal] =
    useState(false);

  useEffect(() => {
    setNotificationOrganizations(activeNotification.organizations);
  }, [activeNotification]);

  useEffect(() => {
    setNotificationOrganizationIds(
      notificationOrganizations.map((org) => org.id)
    );
  }, [notificationOrganizations]);

  useEffect(() => {
    setSortedNotificationOrganizations(
      sortBy(notificationOrganizations, ['name'])
    );
  }, [notificationOrganizations]);

  const deleteNotificationOrganization = (organization_id) => {
    setIsSaving(true);
    ApiServiceServerless.delete(
      `/user_notification_organization/${activeNotification.id}/${organization_id}`
    )
      .then((res) => {
        setNotifications((prev) =>
          prev.map((notification) => {
            if (notification.id === activeNotification.id) {
              return { ...res.data };
            }
            return notification;
          })
        );
        showToast(
          'success',
          'Success',
          `Notification Organization was deleted.`
        );
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        throw error;
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const tableColumns = [
    {
      dataField: 'organization_name',
      text: 'Organization',
      style: { paddingTop: '10px' },
    },
    {
      dataField: 'organization_id',
      text: 'Actions',
      formatExtraData: {
        isSaving: isSaving,
      },
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <Col>
          <Button
            variant='danger'
            onClick={() => {
              deleteNotificationOrganization(cell);
            }}
            disabled={formatExtraData.isSaving}
          >
            <FontAwesomeIcon
              data-id='delete-org-btn'
              style={{
                fontSize: '20px',
                lineHeight: 'inherit',
                paddingTop: '2px',
              }}
              icon={faTrashAlt}
            />
          </Button>
        </Col>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col>
          <h5>Organizations</h5>
        </Col>
        <Col>
          <div
            style={{
              textAlign: 'right',
              marginBottom: '1em',
            }}
          >
            <FontAwesomeIcon
              style={getStyles(isSaving).iconStyle}
              icon={faBuildings}
              title={'Add Organization'}
              disabled={isSaving}
              onClick={() => {
                if (!isSaving) {
                  setShowAddOrganizationModal(true);
                }
              }}
            />
          </div>
        </Col>
      </Row>
      <EnergyTracerTable
        data={sortedNotificationOrganizations}
        columns={tableColumns}
        keyField={'organization_id'}
      />
      <AddUserNotificationOrganizationModal
        show={showAddOrganizationModal}
        onHide={() => {
          setShowAddOrganizationModal(false);
        }}
        activeNotification={activeNotification}
        userOrganizations={userOrganizations}
        notificationOrganizationIds={notificationOrganizationIds}
        setNotifications={setNotifications}
        showToast={showToast}
        isSaving={isSaving}
        setIsSaving={setIsSaving}
      />
    </>
  );
}
