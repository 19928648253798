import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faSyncAlt, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';

import ApiServiceServerless from '../../../xhr_libs';
import UserNotificationOrganizations from './UserNotificationOrganizations';
import UserNotificationReportOptions from './UserNotificationReportOptions';
import { UserNotificationFrequencyDisplay } from './UserNotificationFrequency';
import ConfirmationModal, {
  useConfirm,
} from '../../../components/modals/ConfirmationModal';
import EditUserNotificationModal from '../../../components/modals/UserNotification/EditUserNotificationModal';
import ResourceDetails from '../../../components/ResourceDetails';

const NOTIFICATION_TYPE_LOOKUP = {
  automated_data: 'Automated Data Summary',
  scheduled_reports: 'Scheduled Report',
};

const ORGANIZATION_NOTIFICATION_TYPES = ['automated_data'];

const getStyles = (disabled) => {
  return {
    iconStyle: {
      fontSize: '16px',
      margin: '0 0.35em',
      verticalAlign: 'text-bottom',
      cursor: disabled ? 'none' : 'pointer',
      color: 'var(--et_tab_grey)',
      opacity: disabled ? '0.5' : '1.0',
    },
  };
};

export default function UserNotification(props) {
  const {
    userOrganizations,
    activeNotification,
    setNotifications,
    setActiveNotificationId,
    setIsLoading,
    isSaving,
    setIsSaving,
    showToast,
  } = props;

  const [confirmationModalProps, withConfirm] = useConfirm();
  const [confirmText, setConfirmText] = useState('Confirm');
  const [submitVariant, setSubmitVariant] = useState('success');

  const [showEditModal, setShowEditModal] = useState(false);

  const onDeleteClick = () => {
    setConfirmText('Delete');
    setSubmitVariant('danger');
    let msg = `Are you sure you want to delete this notification? This cannot be undone.`;

    const beginSubscriptionDelete = () => {
      setIsLoading(true);
      handleDelete();
    };
    withConfirm(msg, beginSubscriptionDelete);
  };

  const handleDelete = () => {
    ApiServiceServerless.delete(`/user_notifications/${activeNotification.id}`)
      .then(() => {
        setActiveNotificationId(null);
        setNotifications((prev) =>
          prev.filter(
            (notification) => notification.id !== activeNotification.id
          )
        );
        showToast('success', 'Success', `Notification was deleted.`);
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        throw error;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleRefreshClick = () => {
    setConfirmText('Confirm');
    setSubmitVariant('success');
    let msg = `Are you sure you resend notification? This may take several minutes, please do not request another resend if you have done so recently.`;

    const beginResendNotification = () => {
      setIsSaving(true);
      resendNotification();
    };
    withConfirm(msg, beginResendNotification);
  };

  const resendNotification = () => {
    ApiServiceServerless.get(`/user_notifications/${activeNotification.id}`)
      .then((res) => {
        setNotifications((prev) =>
          prev.map((notification) => {
            if (notification.id === activeNotification.id) {
              return { ...res.data };
            }
            return notification;
          })
        );
        showToast('success', 'Success', `Notification was resent.`);
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        throw error;
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <div style={{ position: 'relative' }}>
      <h4 style={{ display: 'inline-block', marginTop: '0.5em', padding: '0' }}>
        {activeNotification.name}
      </h4>
      <div style={{ position: 'absolute', top: 0, right: 0 }}>
        <FontAwesomeIcon
          style={getStyles(isSaving).iconStyle}
          icon={faPen}
          title={'Edit Notification'}
          disabled={isSaving}
          onClick={() => {
            if (!isSaving) {
              setShowEditModal(true);
            }
          }}
        />
        <FontAwesomeIcon
          style={getStyles(isSaving).iconStyle}
          icon={faSyncAlt}
          title={'Resend Notification'}
          disabled={isSaving}
          onClick={() => {
            if (!isSaving) {
              handleRefreshClick(true);
            }
          }}
        />

        <FontAwesomeIcon
          style={getStyles(isSaving).iconStyle}
          icon={faTrashAlt}
          title={'Delete Notification'}
          disabled={isSaving}
          onClick={() => {
            if (!isSaving) {
              onDeleteClick();
            }
          }}
        />
      </div>
      <Row>
        <Col md={2}>
          <ResourceDetails
            header='Notification Type'
            value={
              NOTIFICATION_TYPE_LOOKUP[activeNotification.notification_type]
            }
          />
        </Col>
        {activeNotification.notification_type === 'scheduled_reports' && (
          <Col md={2}>
            <ResourceDetails
              header='Organization'
              value={
                activeNotification.organizations.length > 0
                  ? activeNotification.organizations[0].organization_name
                  : ''
              }
            />
          </Col>
        )}
        <UserNotificationFrequencyDisplay
          frequencyOption={activeNotification.frequency_option}
          frequencyValue={activeNotification.frequency_value}
        />
      </Row>
      <br></br>
      {ORGANIZATION_NOTIFICATION_TYPES.includes(
        activeNotification.notification_type
      ) && (
        <UserNotificationOrganizations
          userOrganizations={userOrganizations}
          activeNotification={activeNotification}
          setNotifications={setNotifications}
          showToast={showToast}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
        />
      )}
      {activeNotification.notification_type === 'scheduled_reports' && (
        <UserNotificationReportOptions
          activeNotification={activeNotification}
          setNotifications={setNotifications}
          showToast={showToast}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
        />
      )}
      <ConfirmationModal
        {...confirmationModalProps}
        confirmText={confirmText}
        submitVariant={submitVariant}
      />
      <EditUserNotificationModal
        show={showEditModal}
        onHide={() => {
          setShowEditModal();
        }}
        showToast={showToast}
        activeNotification={activeNotification}
        setNotifications={setNotifications}
        isSaving={isSaving}
        setIsSaving={setIsSaving}
      />
    </div>
  );
}
