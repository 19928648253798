import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

function SmallerLoader(props) {
  const { text, style } = props;

  return (
    <>
      {text} &nbsp;
      <FontAwesomeIcon
        className='fa-spin'
        icon={faSyncAlt}
        style={{ ...style }}
      />
    </>
  );
}

SmallerLoader.defaultProps = {
  style: {},
};

export default SmallerLoader;
