import React, { useState, useEffect } from 'react';
import { Modal, Form } from 'react-bootstrap';

import { ApiServiceServerless } from '../../../xhr_libs';
import AddEntityActionButtons from '../../forms/AddEntityActionButtons';
import { UserNotificationFrequencySelect } from '../../../pages/Profile/UserNotifications/UserNotificationFrequency';

export default function EditUserNotificationModal(props) {
  const {
    show,
    onHide,
    showToast,
    activeNotification,
    setNotifications,
    isSaving,
    setIsSaving,
  } = props;

  const [editName, setEditName] = useState('');
  const [editFrequencyOption, setEditFrequencyOption] = useState('');
  const [editFrequencyValue, setEditFrequencyValue] = useState('');

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (show) {
      setEditName(activeNotification.name);
      setEditFrequencyOption(activeNotification.frequency_option);
      setEditFrequencyValue(activeNotification.frequency_value);
    }
  }, [activeNotification, show]);

  useEffect(() => {
    setDisabled(true);
    if (
      editName !== activeNotification.name ||
      editFrequencyOption !== activeNotification.frequency_option ||
      editFrequencyValue !== activeNotification.frequency_value
    ) {
      setDisabled(false);
    }
    if (editName === '') {
      setDisabled(true);
    }
  }, [activeNotification, editName, editFrequencyOption, editFrequencyValue]);

  const formatPayload = (
    activeNotification,
    editName,
    editFrequencyOption,
    editFrequencyValue
  ) => {
    const payload = {};

    if (editName !== activeNotification.name) {
      payload.name = editName;
    }

    if (
      editFrequencyOption !== activeNotification.frequency_option ||
      editFrequencyValue !== activeNotification.frequency_value
    ) {
      payload.frequency_option = editFrequencyOption;
      payload.frequency_value = editFrequencyValue;
    }
    return payload;
  };

  const addNotification = (e) => {
    e.preventDefault();
    setIsSaving(true);
    const payload = formatPayload(
      activeNotification,
      editName,
      editFrequencyOption,
      editFrequencyValue
    );
    ApiServiceServerless.post(
      `/user_notifications/${activeNotification.id}`,
      payload
    )
      .then((res) => {
        setNotifications((prev) =>
          prev.map((notification) => {
            if (notification.id === activeNotification.id) {
              return { ...res.data };
            }
            return notification;
          })
        );
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        throw error;
      })
      .finally(() => {
        onHide();
        setIsSaving(false);
      });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>Add Notification</Modal.Header>
      <Form onSubmit={addNotification}>
        <Modal.Body>
          <Form.Group>
            <Form.Label>
              {'Notification Name'}
              <span className='text-warning font-weight-bold pl-1'>*</span>
            </Form.Label>
            <Form.Row>
              <Form.Control
                required
                value={editName}
                onChange={(e) => setEditName(e.target.value)}
              />
            </Form.Row>
          </Form.Group>

          <UserNotificationFrequencySelect
            notificationType={activeNotification.notification_type}
            frequencyOption={editFrequencyOption}
            setFrequencyOption={setEditFrequencyOption}
            frequencyValue={editFrequencyValue}
            setFrequencyValue={setEditFrequencyValue}
          />
        </Modal.Body>
        <Modal.Footer>
          <AddEntityActionButtons
            onCancel={onHide}
            submitDisabled={disabled}
            isSubmitting={isSaving}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
