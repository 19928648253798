import React, { useEffect, useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { sortBy } from 'lodash';
import dayjs from 'dayjs';

import AddEntityActionButtons from '../../forms/AddEntityActionButtons';
import { ApiServiceServerless } from '../../../xhr_libs';
import { UserNotificationFrequencySelect } from '../../../pages/Profile/UserNotifications/UserNotificationFrequency';

export default function AddUserNotificationReportModal(props) {
  const {
    show,
    onHide,
    ReportLookup,
    organizationData,
    organization_id,
    organization_name,
    userNotifications,
    setUserNotifications,
    reportPrimaryType,
    reportSecondaryType,
    payload,
    showToast,
  } = props;

  const [notificationId, setNotificationId] = useState('');
  const [dateOffsets, setDateOffsets] = useState({});

  const [newName, setNewName] = useState('');
  const [newFrequencyOption, setNewFrequencyOption] = useState(0);
  const [newFrequencyValue, setNewFrequencyValue] = useState('0');

  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setDateOffsets(
      createDateOffsets(
        payload,
        ReportLookup,
        organizationData,
        reportPrimaryType,
        reportSecondaryType
      )
    );
  }, [
    payload,
    ReportLookup,
    reportPrimaryType,
    reportSecondaryType,
    organizationData,
  ]);

  const clearForm = () => {
    setNotificationId('');
    setNewName('');
    setNewFrequencyOption(0);
    setNewFrequencyValue('0');
  };

  useEffect(() => {
    if (show) {
      clearForm();
    }
  }, [show]);

  const handleSubmit = (e) => {
    setIsSaving(true);
    e.preventDefault();
    if (notificationId === 0) {
      addNewNotification();
    } else {
      addNotificationReport(notificationId);
    }
  };

  const addNewNotification = () => {
    ApiServiceServerless.post(`/user_notifications`, {
      name: newName,
      notification_type: 'scheduled_reports',
      organization_id: organization_id,
      frequency_option: newFrequencyOption,
      frequency_value: newFrequencyValue,
    })
      .then((res) => {
        setUserNotifications((prev) => [...prev, res.data]);
        addNotificationReport(res.data.id, dateOffsets);
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        setIsSaving(false);
        throw error;
      });
  };

  const addNotificationReport = (notification_id) => {
    ApiServiceServerless.post(
      `/user_notification_report`,
      {
        organization_id: organization_id,
        notification_id: notification_id,
        report_primary_type: reportPrimaryType,
        report_secondary_type: reportSecondaryType,
        payload: { ...payload, organization_id: organization_id },
        date_offsets: dateOffsets,
      },
      {}
    )
      .then((res) => {
        setUserNotifications((prev) =>
          prev.map((notif) => {
            if (notif.id !== res.data.id) return notif;
            return res.data;
          })
        );

        showToast('success', 'Success', 'Report added to notification');
        onHide();
        clearForm();
        setIsSaving(false);
      })
      .catch((err) => {
        showToast('danger', 'Error', err);
        onHide();
        setIsSaving(false);
        throw err;
      });
  };

  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton style={{ height: '50px' }}>
          {'Add Report to Notification'}
        </Modal.Header>
        <Form
          noValidate
          onSubmit={handleSubmit}
          style={{
            textAlign: 'left',
          }}
        >
          <Modal.Body>
            <Row>
              <Col sm={12}>
                <Form.Group>
                  <Form.Label>Notification </Form.Label>
                  <Form.Control
                    as='select'
                    onChange={(e) =>
                      setNotificationId(parseInt(e.target.value))
                    }
                  >
                    <option key={'null-val'} value={''} hidden></option>
                    {userNotifications.filter(
                      (notif) =>
                        notif.notification_type === 'scheduled_reports' &&
                        notif.organizations.length > 0 &&
                        notif.organizations[0].organization_id ===
                          organization_id
                    ).length === 0 && (
                      <option key={'null-null'} value={''} disabled>
                        No available notifications
                      </option>
                    )}
                    {sortBy(
                      userNotifications.filter(
                        (notif) =>
                          notif.notification_type === 'scheduled_reports' &&
                          notif.organizations.length > 0 &&
                          notif.organizations[0].organization_id ===
                            organization_id
                      ),
                      ['name']
                    ).map((notif) => (
                      <option
                        key={notif.id}
                        value={notif.id}
                        disabled={notif.reports.length >= 5}
                      >
                        {`${notif.name} (${notif.reports.length}/5)`}
                      </option>
                    ))}
                    <option key='new' value={0}>
                      Create New Notification
                    </option>
                  </Form.Control>
                </Form.Group>
                {notificationId === 0 && (
                  <>
                    <Form.Group>
                      <Form.Label>
                        {'Notification Name'}
                        <span className='text-warning font-weight-bold pl-1'>
                          *
                        </span>
                      </Form.Label>
                      <Form.Row>
                        <Form.Control
                          required
                          placeholder=''
                          onChange={(e) => setNewName(e.target.value)}
                        />
                      </Form.Row>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>
                        {'Organization'}
                        <span className='text-warning font-weight-bold pl-1'>
                          *
                        </span>
                      </Form.Label>
                      <Form.Row>
                        <Form.Control as='select' required disabled={true}>
                          <option key={organization_id} value={organization_id}>
                            {organization_name}
                          </option>
                        </Form.Control>
                      </Form.Row>
                    </Form.Group>
                    <UserNotificationFrequencySelect
                      notificationType={'scheduled_reports'}
                      frequencyOption={newFrequencyOption}
                      setFrequencyOption={setNewFrequencyOption}
                      frequencyValue={newFrequencyValue}
                      setFrequencyValue={setNewFrequencyValue}
                    />
                  </>
                )}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <AddEntityActionButtons
              onCancel={onHide}
              submitDisabled={
                notificationId === null ||
                notificationId === '' ||
                (notificationId === 0 && newName === '')
              }
              submitShow={true}
              isSubmitting={isSaving}
            />
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

const createDateOffsets = (
  payload,
  ReportLookup,
  organizationData,
  reportPrimaryType,
  reportSecondaryType
) => {
  let date_offsets = {};

  const report = ReportLookup.find(
    (report) => report.type === reportPrimaryType
  ).reportInfo.find((report) => report.type === reportSecondaryType);

  if (report) {
    const today = dayjs(new Date()).startOf('month');
    if ([1, 2].includes(report.notification_variant)) {
      const endDate = dayjs.utc(
        new Date(payload['end_year'], payload['end_month'] - 1, 1)
      );
      date_offsets['end_date_offset'] = today.diff(endDate, 'month');
    }

    if ([1.1, 2].includes(report.notification_variant)) {
      const startDate = dayjs.utc(
        new Date(payload['start_year'], payload['start_month'] - 1, 1)
      );
      date_offsets['start_date_offset'] = today.diff(startDate, 'month');
    }

    if (report.notification_variant === 3) {
      // performance stuff
      if (organizationData['performance_projects']) {
        const project =
          organizationData['performance_projects'][
            payload['performance_project']
          ];
        if (project) {
          let delta = today.diff(
            dayjs.utc(project['savings_start_date']),
            'month'
          );
          delta =
            delta -
            (payload['performance_year'] - 1) * 12 -
            (payload['performance_month'] - 1);
          date_offsets['end_date_offset'] = delta;
        }
      }
    }

    // special case for trend and ghg_summary
    if (
      ['trend', 'trend_excel', 'trend_building', 'ghg_summary'].includes(
        report.type
      )
    ) {
      date_offsets['baseline_year_offset'] =
        today.year() - payload['baseline_year'];

      if (payload['baseline2_year'] && payload['baseline2_year'] !== 'none') {
        date_offsets['baseline2_year_offset'] =
          today.year() - payload['baseline2_year'];
      }
    }

    // if notification_variant === 0 do nothing because report does not have dates
  }
  return date_offsets;
};
